import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {openModal, closeModal} from './modules/modal';
import {getImask} from './modules/init-iMask';
import {validatePhone} from './modules/validate-phone';
import {fileUpload} from './modules/file-upload';
import {dropdown} from './modules/dropdown';

import {getActiveServiceBtn} from './modules/get-active-service-btn';
import {changePages} from './modules/change-pages';

// Utils
// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------

openModal();
closeModal();
getImask();
validatePhone();
fileUpload();
dropdown();

getActiveServiceBtn();
changePages();
