// позволяет менять контент на главной странице
// переходит к "сайты", "приложения", "брендинг"
const changePages = () => {
  const services = document.querySelector('.services');

  if (!services) {
    return;
  }

  const serviceBtns = Array.from(services.querySelectorAll('.services__item-btn'));
  const servicesContent = services.querySelector('.container__content');
  const pages = Array.from(document.querySelectorAll('.container__content--shift'));
  const btnEllipse = document.querySelector('.blocks-btn');
  const logo = document.querySelector('.header__logo');
  const arrowControlBtn = document.querySelector('.btn--arrow');
  const btnChangePages = document.querySelector('.main-nav__list .white');
  const btnChangePagesMobile = document.querySelector('.mobile-menu__list .btn');

  const removeClassesOnScroll = () => {
    const containersContentActive = services.querySelector('.container__content--active');

    setTimeout(function () {
      servicesContent.classList.remove('container__content--disabled');
      containersContentActive.classList.add('container__content--shift');
      containersContentActive.classList.remove('container__content--active');
    }, 1500);

    arrowControlBtn.classList.remove('js-active');
    btnChangePages.classList.remove('js-active');
    btnChangePages.classList.add('is-active');
    btnChangePages.classList.add('white-active-link');
    logo.classList.add('hover-disabled');
    btnEllipse.classList.add('disabled');
    btnEllipse.classList.remove('btn--ellipse-active');

    logo.blur();
    serviceBtns.forEach(function (btn) {
      btn.blur();
    });

    window.removeEventListener('wheel', removeClassesOnScroll);
    window.removeEventListener('scroll', removeClassesOnScroll);
    arrowControlBtn.removeEventListener('click', removeClassesOnScroll);
    logo.removeEventListener('click', removeClasses);
    btnChangePages.removeEventListener('click', removeClasses);
    btnChangePagesMobile.removeEventListener('click', removeClasses);
    window.removeEventListener('touchmove', removeClassesOnScroll);
  };

  const removeClasses = () => {
    const containerContentActive = services.querySelector('.container__content--active');
    const pageTitleActive = services.querySelector('.page__title--active');
    const pageTextActive = services.querySelector('.page__text--active');

    pageTitleActive.classList.add('page__title--fade-in');
    pageTextActive.classList.add('page__text--fade-in');
    servicesContent.classList.add('container__content--fade-out');
    servicesContent.classList.remove('container__content--disabled');
    containerContentActive.classList.add('container__content--fade-in');
    arrowControlBtn.classList.remove('js-active');
    btnChangePages.classList.remove('js-active');
    btnChangePages.classList.add('is-active');
    btnChangePages.classList.add('white-active-link');
    logo.classList.add('hover-disabled');
    btnEllipse.classList.add('disabled');
    btnEllipse.classList.remove('btn--ellipse-active');

    logo.blur();
    serviceBtns.forEach(function (btn) {
      btn.blur();
    });

    setTimeout(function () {
      services.querySelector('.container__content--fade-out').classList.remove('container__content--fade-out');
      containerContentActive.classList.add('container__content--shift');
      containerContentActive.classList.remove('container__content--active');
      pageTitleActive.classList.remove('page__title--fade-in');
      pageTitleActive.classList.remove('page__title--active');
      pageTextActive.classList.remove('page__text--fade-in');
      pageTextActive.classList.remove('page__text--active');
      services.querySelector('.container__content--fade-in').classList.remove('container__content--fade-in');
    }, 3000);

    window.removeEventListener('wheel', removeClassesOnScroll);
    window.removeEventListener('scroll', removeClassesOnScroll);
    arrowControlBtn.removeEventListener('click', removeClassesOnScroll);
    logo.removeEventListener('click', removeClasses);
    btnChangePages.removeEventListener('click', removeClasses);
    btnChangePagesMobile.removeEventListener('click', removeClasses);
    window.removeEventListener('touchmove', removeClassesOnScroll);
  };

  const reverseScreen = () => {
    window.addEventListener('wheel', removeClassesOnScroll);
    window.addEventListener('scroll', removeClassesOnScroll);
    logo.addEventListener('click', removeClasses)
    arrowControlBtn.addEventListener('click', removeClassesOnScroll);
    btnChangePages.addEventListener('click', removeClasses);
    btnChangePagesMobile.addEventListener('click', removeClasses);
    window.addEventListener('touchmove', removeClassesOnScroll);
  };

  const changePage = (evt) => {
    const pageTitles = Array.from(services.querySelectorAll('.page__title'));
    const pageTexts = Array.from(services.querySelectorAll('.page__text'));

    servicesContent.classList.add('container__content--disabled');

    const index = serviceBtns.indexOf(evt.target);

    pages[index].classList.remove('container__content--shift');
    pages[index].classList.add('container__content--active');
    pageTitles[index].classList.add('page__title--active');
    pageTexts[index].classList.add('page__text--active');
    arrowControlBtn.classList.add('js-active');
    btnChangePages.classList.add('js-active');
    btnChangePages.classList.remove('is-active');
    btnChangePages.classList.remove('white-active-link');
    logo.classList.remove('hover-disabled');

    reverseScreen();
  };

  serviceBtns.forEach(function (btn) {
    btn.addEventListener('click', changePage);
  });
};

export {changePages};
