const getActiveServiceBtn = () => {
  const serviceBtns = Array.from(document.querySelectorAll('.services__item-btn'));

  if (!serviceBtns) {
    return;
  }

  const changeBtn = (evt) => {
    serviceBtns.forEach(btn => {
      btn.classList.remove('services__item-btn--active');
    });

    evt.target.classList.add('services__item-btn--active');

    serviceBtns.forEach(btn => {
      btn.addEventListener('mouseout', function () {
        btn.classList.remove('services__item-btn--active');
        serviceBtns[1].classList.add('services__item-btn--active');
      });
    });

    serviceBtns.forEach(btn => {
      btn.addEventListener('focusout', function () {
        btn.classList.remove('services__item-btn--active');
        serviceBtns[1].classList.add('services__item-btn--active');
      });
    });
  };

  serviceBtns.forEach(btn => {
    btn.addEventListener('mouseover', changeBtn);
    serviceBtns[1].classList.add('services__item-btn--active');
  });

  serviceBtns.forEach(btn => {
    btn.addEventListener('focusin', changeBtn);
  });
};

export {getActiveServiceBtn};
