// Открывает и закрывает списки
const dropdown = () => {
  let buttons = document.querySelectorAll('.js-dropdown-btn');

  if (!buttons) {
    return;
  }

  // Вешаем обработчик событий на каждый элемент
  buttons.forEach((btn) => {
    btn.addEventListener('click', function (evt) {
      // this текущая кнопка, где с помощью метода nextElementSibling
      // выбираем соседний элемент и добавляем в него css класс
      // eslint-disable-next-line no-invalid-this
      this.nextElementSibling.classList.toggle('text-confident--active');
      evt.preventDefault();
    });
  });
};

export {dropdown};
